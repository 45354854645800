/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-14",
    versionChannel: "nightly",
    buildDate: "2023-10-14T00:21:06.189Z",
    commitHash: "93aa3a493ba4c42f80e5d5a17044702e4942f89d",
};
